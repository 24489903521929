import React, { useState, useEffect, Fragment } from 'react';
import { Row, Col, Form /*, Container, Button, InputGroup, Badge*/ } from 'react-bootstrap';
//import DataTable from '../components/DataTable';
//import { useSelector, useDispatch } from 'react-redux';
import { /*Trans, */useTranslation } from 'react-i18next';
//import { AuthHandler } from '../utils/AuthHandler';
//import { RestService } from '../utils';
import ConfigHandler from '../config';
//import Icon from '@mui/material/Icon';
//import Utils from '../utils/Utils';
//import { Link } from 'react-router-dom';
import { useTable } from 'react-table';
import { toast } from 'react-toastify';

import '../scss/components/DataTables.scss';

function QuoteCoverageTable({
    istat,
    egon,
    civico,
    bnumber,
    data,
    keyID,
    city,
    street,
    particellaToponomastica,
    snumber,
    calculateTotal,
    editingMode,
    serviceID,
    view,
    quoteID,
    partner,
    setSelectedService,
    setIsLoadingContent
  }) {

  const [listData, setListData] = useState(data);
  const [selectedPricebook, setSelectedPriceBook] = useState({});
  const [/*isFatibility*/, setIsFatibility] = useState(false);
  const [confirmMessage, setConfirmMessage] = useState('');
  const [showButton, setShowButton] = useState({ show: false, rowIndex: null });
  const { t/*, i18n*/ } = useTranslation();
  const [buttonClicked, setButtonClicked] = useState(false);

  useEffect(() => {
    if (view === undefined || view === null) {
      view = false;
    }

    if (editingMode) {
      if (serviceID != null || undefined) {
        data = [serviceID.comunicaInfo];
        setTimeout(setSelectedPriceBook(serviceID.comunicaInfo.listino[0]), 2500);

        document.getElementById(`select-${keyID}`).focus();
        document.getElementById(`select-${keyID}`).value = serviceID.comunicaInfo.listino[0].descrizione;
        setShowButton({ show: true, rowIndex: 0 });
        let btn=document.getElementById(`selectBTN-${keyID}`);
        if(btn){
          setTimeout(() => { if(!!btn) btn.click(); }, 500);
          setTimeout(() => { if(!!btn) btn.disabled = true; }, 600);
        }
        setIsLoadingContent(false);
      }
    }
  }, []);

  const translateComunicaKey = (key) => {
      var valueFromJSON = t(`COMUNICAKEYS.${key}`)
      if(valueFromJSON.includes("COMUNICAKEYS")){
        return key;
      }else{
        return valueFromJSON;
      }
  }

  const getValues = (rawdata) => {
    var returnValues = [];

    Object.entries(rawdata).map(([key, val]) => {
      if (val !== '' || undefined) {
        returnValues.push(
          <li key={`${key}-${val}`}>
            {translateComunicaKey(key)}: <b>{translateComunicaKey(val)}</b>
          </li>,
        );
      }
      return false;
    });

    return returnValues;
  };

  const giveToast = (selectedVal) => {
    if (editingMode === false) {
      toast.success(`${selectedVal} selected!`);
    }
  };

  const getPricebookValues = (rawPricebook) => {
    var returnPricebookValues = [];
    if (rawPricebook.length > 0) {
      for (var i = 0; i <= rawPricebook.length; i++) {
        var obj = rawPricebook[i];
        if (obj) {
          returnPricebookValues.push(
            <option
              value={obj.descrizione}
              data-priceid={obj.id}
              data-fattibilita={obj.fattibilita}
              data-nrc={obj.nrc != null ? obj.nrc : null}
              data-mrc={obj.mrc != null ? obj.mrc : null}
              data-durata={obj.durata != null ? obj.durata : null}
              key={`priceoption-${keyID}-${i}`}>
              {obj.descrizione}
              {obj.mrc !== null && localStorage.getItem('SHOW_PRICEBOOK_PRICE') === 'true' ? ` (MRC: ${obj.mrc}€) ` : null}
              {obj.nrc !== null && localStorage.getItem('SHOW_PRICEBOOK_PRICE') === 'true' ? ` (NRC: ${obj.nrc}€) ` : null}
              {obj.durata !== null && localStorage.getItem('SHOW_PRICEBOOK_PRICE') === 'true' && obj.durata>0 ? ` (MONTHS: ${obj.durata}) ` : null}
            </option>,
          );
        }
      }
    }

    return returnPricebookValues;
  };

  const buttonStatus = (fattibilita) => {
    if (buttonClicked) {
      if (fattibilita === true) {
        return 'selectedButton feasibility';
      } else {
        return 'selectedButton confirm';
      }
    } else {
      return 'unselectedButton';
    }
  };

  useEffect(() => {
    setListData(data);
  }, [data]);

  const handleSelect = (e, rowindex) => {
    const datafattibilita = e.target.options[e.target.selectedIndex].dataset.fattibilita;
    const datamrc = e.target.options[e.target.selectedIndex].dataset.mrc;
    const datanrc = e.target.options[e.target.selectedIndex].dataset.nrc;
    const datadurata = e.target.options[e.target.selectedIndex].dataset.durata;
    const dataid = e.target.options[e.target.selectedIndex].dataset.priceid;
//    if (e.target.value === t('NEWQUOTE.COVERAGE.DefaultSelectLabel')) {
      localStorage.removeItem(`SITE-SELECTED-${keyID}`);
      setSelectedPriceBook({ id: null, nrc: null, mrc: null, descrizione: null, fattibilita: null, durata: null });
      setListData(data);
      setConfirmMessage('');
      setShowButton({ show: false, rowIndex: null });
      setButtonClicked(false);
      calculateTotal();
//    }
    if (e.target.value !== t('NEWQUOTE.COVERAGE.DefaultSelectLabel')) {
      setSelectedPriceBook({
        id: dataid,
        nrc: datanrc,
        mrc: datamrc,
        descrizione: e.target.value,
        fattibilita: datafattibilita,
        durata: datadurata,
      });
      setShowButton({ show: true, rowIndex: rowindex });
    }

    setIsFatibility(datafattibilita);
  };

  function Table({ columns, data }) {
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
      columns,
      data,
    });

    return (
      <table {...getTableProps()} className="DataTables">
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()} style={{ borderBottom: '1px solid #faa' }}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>{column.render('Header')}</th>
              ))}
            </tr>
          ))}
        </thead>

        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <Fragment key={`NewQuote-wrapper-${row.id}`}>
                <tr {...row.getRowProps()} className={`QuoteSubDiv ${row.id % 2 ? ' QuoteSubDivAlt ' : ''}`}>
                  {row.cells.map((cell) => {
                    return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
                  })}
                </tr>
                <tr role="row" className={`QuoteSubDiv ${row.id % 2 ? ' QuoteSubDivAlt ' : ''}`}>
                  <td colSpan={4} style={{ border: 'none' }}>
                    <Row className="mainContainer">
                      <Col md={5}>
                        <ul>{getValues(row.original.statoCoperturaServizio.parametri)}</ul>
                      </Col>

                      <Col md={6} className="pricebook">
                        {getPricebookValues(row.original.listino) !== '' || undefined ? (
                          <>
                            <Form.Select
                              disabled={view ? true : false}
                              onChange={(e) => {
                                handleSelect(e, row.index);
                              }}
                              value={selectedPricebook.descrizione}
                              id={`select-${keyID}`}>
                              <option>{t('NEWQUOTE.COVERAGE.DefaultSelectLabel')}</option>
                              {getPricebookValues(row.original.listino)}
                            </Form.Select>
                          </>
                        ) : null}
                      </Col>

                      <Col style={{ alignSelf: 'center' }} className="item-center">
                        {showButton.show === true && showButton.rowIndex === row.index ? (
                          <>
                            <button
                              id={`selectBTN-${keyID}`}
                              className={buttonStatus(row.original.listino[0].fattibilita)}
                              onClick={() => {
                                localStorage.setItem(
                                  `SITE-SELECTED-${keyID}`,
                                  JSON.stringify({
                                    city: city,
                                    street: editingMode ? street : `${particellaToponomastica} ${street}`,
                                    streetNumber: snumber,
                                    buildingNumber: bnumber,
                                    istatCode: istat,
                                    egonCode: egon,
                                    quoteId: editingMode && serviceID ? serviceID.quoteId : quoteID,
                                    id: editingMode && serviceID != null ? serviceID.id : null,
                                    comunicaInfo: {
                                      tipoServizioCopertura: row.original.tipoServizioCopertura,
                                      statoCoperturaServizio: row.original.statoCoperturaServizio,
                                      pianoDiCopertura: row.original.pianoDiCopertura,
                                      listino: [selectedPricebook],
                                    },
                                    pricebookId: selectedPricebook.id,
                                  }),
                                );
                                giveToast(row.original.tipoServizioCopertura.descrizione);
                                setListData([row.original]);
                                calculateTotal();
                                setConfirmMessage(
                                  row.original.listino[0].fattibilita === true
                                    ? t('NEWQUOTE.COVERAGE.ButtonFeasability')
                                    : t('OTHERS.ConfirmSelectLabel'),
                                );
                                setShowButton({ show: true, rowIndex: 0 });
                                setButtonClicked(true);
                              }}>
                              ✔
                            </button>
                            <p style={{ height: '40px', marginBottom: '-40px' }} className="fadedText">
                              {confirmMessage}
                            </p>
                          </>
                        ) : null}
                      </Col>
                      {selectedPricebook.descrizione && buttonClicked && (
                        setSelectedService(selectedPricebook.descrizione)
                      )}
                    </Row>
                  </td>
                </tr>
              </Fragment>
            );
          })}
        </tbody>
      </table>
    );
  }

  const columns = React.useMemo(
    () => [
      {
        Header: t('NEWQUOTE.COVERAGE.TH_Provider'),
        accessor: 'providerId',
        Cell: (props) => {
          if(props.row.original.provider != null){
            return (
              <div>
                <img src={`${ConfigHandler.getConfig().api.baseUrl}/provider/image?name=${props.row.original.provider}`} width="100px" alt="value"/>
              </div>
            )
          } else {
            return props.row.original.pianoDiCopertura.denominazione;
          }
        }
      },
      {
        Header: t('NEWQUOTE.COVERAGE.TH_Coverage'),
        accessor: 'statoCoperturaServizio.descrizione',
      },
      {
        Header: t('NEWQUOTE.COVERAGE.TH_Line'),
        accessor: 'tipoServizioCopertura.descrizione',
      },
      {
        Header: t('NEWQUOTE.COVERAGE.TH_NetworkType'),
        accessor: 'statoCoperturaServizio.parametri.Tipo elemento di rete',
      },
    ],
    [t],
  );

  if (listData === '' || listData === undefined) {
    setListData({
      'providerId': t('NEWQUOTE.COVERAGE.NoServices'),
      'statoCoperturaServizio.descrizione': '',
      'tipoServizioCopertura.descrizione': '',
      'statoCoperturaServizio.parametri.Tipo elemento di rete': '',
    });
  }

  return (
    <>
    {
      (Array.isArray(listData))
        ? (<Table columns={columns} data={listData} />) 
        : (
          <Row className='mt-5'>
            <Col className="text-center">
              { t('NEWQUOTE.COVERAGE.NoServices') }
            </Col>
          </Row>
        )
    }
    </>
  );
}

export default QuoteCoverageTable;
