import React, { useState, useEffect, Fragment } from 'react';
import { Container, Row, Tooltip, OverlayTrigger } from 'react-bootstrap';
import DataTable from '../components/DataTable';
import { useSelector, useDispatch } from 'react-redux';
import { AuthHandler } from '../utils/AuthHandler';
import { RestService } from '../utils';
import ConfigHandler from '../config';
import Icon from '@mui/material/Icon';
import Utils from '../utils/Utils';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import QuoteCloneConfirmation from '../components/QuoteCloneConfirmation';

import '../scss/components/DataTables.scss';

function ConfirmedQuote() {
  let userRole;
  const { t } = useTranslation();

  if (AuthHandler.isAuth()) {
    userRole = AuthHandler.getUserData();
  }

  const state = useSelector((state) => state);

  //Needed Variables
  const [listData, setData] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [sortDirection, setSortDir] = useState(false);
  const [sortVal, setSortVal] = useState('dateInserted:desc');
  const [numofData, setnumofData] = useState(0);
  const [sortIcon, setSortIcon] = useState('expairingDate');
  const [showCloneModal, setShowCloneModal] = useState(false);
  const [toCloneData, setToCloneData] = useState(null);
  let dataReturn = [];

  //Search Functionalities
  const [inputText, setInputText] = useState('');
  const dispatch = useDispatch();

  //Download Quote
  const downloadQuote = (value) => {
    RestService.get({
      url: `${ConfigHandler.getConfig().api.baseUrl}${ConfigHandler.getConfig().api.path.getDocument}?id=${value}`
    })
      .then((response) => {
        if (response.ok) {
          return response.blob();
        } else {
          toast.warning(`${t('QUOTELIST.NotificationNoDocumentFoundLabel')} ${value}`);
        }
      })
      .then((res) => {
        const blobURL = window.URL.createObjectURL(res);
        const tempLink = document.createElement('a');
        tempLink.style.display = 'none';
        tempLink.href = blobURL;
        tempLink.setAttribute('download', `Quote_${value}.pdf`);
        if (typeof tempLink.download === 'undefined') {
          tempLink.setAttribute('target', '_blank');
        }
        document.body.appendChild(tempLink);
        tempLink.click();
        document.body.removeChild(tempLink);
        setTimeout(() => {
          window.URL.revokeObjectURL(blobURL);
        }, 100);
      });
  };

  const cloneQuote = (value) => {
    var toDuplicate = { 'id': value.id };
    setToCloneData(toDuplicate);
    setShowCloneModal(true);
  };

  //Fetch new table data when needed
  const fetchData = React.useCallback(({ pageSize, pageIndex }) => {
    const startRow = pageSize * pageIndex;
    const endRow = startRow + pageSize;
    dataReturn = RestService.get({
      url: `${ConfigHandler.getConfig().api.baseUrl}${ConfigHandler.getConfig().api.path.getOrderQuote}?q=${inputText}&offset=${startRow}&limit=${pageSize}&order=${sortVal}`
    }).then((response) =>
      response
        .json()
        .then((data) => ({
          data: data
        }))
        .then((res) => {
          //console.log("DEBUG 91", res.data.data);
          dataReturn = res.data.data;
          if (dataReturn != undefined) {
            setData(dataReturn);
          } else if (dataReturn === undefined) {
            document.getElementsByClassName(`TH-${sortIcon}`)[0].classList.remove('sortedTabAsc', 'sortedTabDesc');
          }
          setnumofData(res.data.total);
          setPageCount(Math.ceil(res.data.total / pageSize));
        })
    );
    const userData = AuthHandler.getUserData();
  });

  //Get user search data
  let inputHandler = (e) => {
    var lowerCase = e.target.value.toLowerCase();
    Utils.handleInputSearch(lowerCase).then((text) => {
      setInputText(text);
    });
  };

  //Handle sorting
  const sortHandler = (val) => {
    document.getElementsByClassName(`TH-${sortIcon}`)[0].classList.remove('sortedTabAsc', 'sortedTabDesc');
    if (sortDirection) {
      setSortVal(`${val}:asc`);
      setSortDir(false);
      document.getElementsByClassName(`TH-${val}`)[0].classList.add('sortedTabAsc');
      setSortIcon(val);
    } else {
      setSortVal(`${val}:desc`);
      setSortDir(true);
      document.getElementsByClassName(`TH-${val}`)[0].classList.add('sortedTabDesc');
      setSortIcon(val);
    }
  };

  //Columns
  let columns = React.useMemo(
    () => [
      {
        Header: t('FORMS.CodeLabel'),
        accessor: 'code'
      },
      {
        Header: t('FORMS.CompanyNameLabel'),
        accessor: 'companyName',
        sortable: false,
        Cell: (props) => {
          return props.row.original.client ? props.row.original.client.companyName : '';
        }
      },
      {
        Header: t('FORMS.LocationLabel'),
        accessor: 'quoteDetails',
        sortable: false,
        Cell: (props) => {
          return props.row.original.quoteDetails ? (
            <ul style={{ listStyle: 'none', paddingLeft: '0px', margin: '0px' }}>
              {props.row.original.quoteDetails.map((val, i) => (
                <Fragment key={`location-${val.id}-${i}`}>
                  <li style={{ textTransform: 'capitalize' }}>
                    <b>Site {i + 1} : </b> {val.street.toLowerCase()} {val.streetNumber}, {val.city.toLowerCase()}
                  </li>
                </Fragment>
              ))}
            </ul>
          ) : (
            ''
          );
        }
      },
      {
        Header: t('FORMS.CreationDateLabel'),
        accessor: 'dateInserted',
        Cell: ({ value }) => {
          return (
            Utils.localizeDate(value)
          )
        }
      },
      {
        Header: t('FORMS.ExpirationDateLabel'),
        accessor: 'expairingDate',
        Cell: ({ value }) => {
          return (
            Utils.localizeDate(value)
          )
        }
      },
      {
        Header: t('FORMS.StatusLabel'),
        accessor: 'status',
        Cell: (props) => {
          return Utils.QuoteStatus(props.row.original.status);
        }
      },
      {
        Header: '',
        accessor: 'dateEdited',
        sortable: false,
        Cell: ({ row }) => {
          return (
            row.original.status != null && (
              <div className="tabIcon">
                <span onClick={() => cloneQuote(row.original)}>
                  <OverlayTrigger overlay={<Tooltip>{t('TOOLTIPS.Duplicate')}</Tooltip>}>
                    <Icon>content_copy</Icon>
                  </OverlayTrigger>
                </span>
              </div>
            )
          );
        }
      },
      {
        Header: ' ',
        accessor: 'crmOpportunityId',
        sortable: false,
        Cell: ({ row }) => {
          return (
            row.original.status === 4 && (
              <div className="tabIcon">
                <span onClick={() => downloadQuote(row.original.crmOpportunityId)}>
                  <OverlayTrigger overlay={<Tooltip>{t('TOOLTIPS.Download')}</Tooltip>}>
                    <Icon>download</Icon>
                  </OverlayTrigger>
                </span>
              </div>
            )
          );
        }
      },
      {
        Header: ' ',
        accessor: 'id',
        sortable: false,
        Cell: ({ row }) => {
          return (
            <div className="tabIcon">
              {row.original.status != null && (
                <span>
                  {row.original.status === 0 ? (
                    <Link
                      to={{
                        pathname: '/requests/editquote',
                        state: { data: row.original }
                      }}>
                      <OverlayTrigger overlay={<Tooltip>{t('TOOLTIPS.Edit')}</Tooltip>}>
                        <Icon>edit_note</Icon>
                      </OverlayTrigger>
                    </Link>
                  ) : (
                    <Link
                      to={{
                        pathname: '/requests/viewquote',
                        state: { quote: row.original }
                      }}>
                      <OverlayTrigger overlay={<Tooltip>{t('TOOLTIPS.View')}</Tooltip>}>
                        <Icon>visibility</Icon>
                      </OverlayTrigger>
                    </Link>
                  )}
                </span>
              )}
            </div>
          );
        }
      }
    ],
    []
  );

  /* Adding a column to the table if the user is an admin. */
  if (AuthHandler.isAuth()) {
    const userRole = AuthHandler.getUserData();
    if (userRole.role[0].toLowerCase() == 'admin') {
      columns = [{
        Header: t('PRICEBOOK.FORM.PartnerLabel'),
        accessor: 'partner.name'
      }, ...columns]
    }
  }

  if (listData == '' || listData === undefined) {
    const data = [
      {
        code: t('OTHERS.NoDataMessage'),
        companyName: '',
        quoteDetails: '',
        username: '',
        email: '',
        dateInserted: '',
        status: null
      }
    ];

    setData(data);
  }

  return (
    <Container fluid>
      {showCloneModal && <QuoteCloneConfirmation setShowCloneModal={setShowCloneModal} data={toCloneData} />}
      <Row className="quoteList">
        {listData.length && (
          <DataTable
            columns={columns}
            data={listData}
            heading={true}
            searchBar={true}
            numofData={numofData}
            tableHeading={t('QUOTELIST.ORDER.TableHeading')}
            searchMessage={t('OTHERS.Search')}
            filterButton={false}
            downloadButton={false}
            newValueButton={userRole.role[0].toLowerCase() === 'admin' ? false : true}
            newValueText={t('QUOTELIST.AddQuote')}
            newValueLink="/requests/newquote"
            inputHandler={inputHandler}
            fetchData={fetchData}
            pageCount={pageCount}
            sortHandler={sortHandler}
            sortValue={sortVal}
            inputText={inputText}
            paginationItemsStartingPoint={ConfigHandler.getConfig().datatables.defaultStartingPoint}
            paginationItemsCount={ConfigHandler.getConfig().datatables.defaultPaginationCount}
          />
        )}
      </Row>
    </Container>
  );
}

export default ConfirmedQuote;
